import { Injectable } from "@angular/core";
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

import { AlertService, IApiErrorResponse } from "@impacgroup/angular-baselib";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { HttpLoadingService } from "./HttpLoadingService";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, private alertService: AlertService, private httpLoadingService: HttpLoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.httpLoadingService.increment();
        return next.handle(req).pipe(
            catchError(
                (err: HttpErrorResponse) => {
                    this.httpLoadingService.decrement();
                    if (err && err.error && err.error.id) {
                        const errorResponse: IApiErrorResponse = err.error;
                        this.alertService.addError(errorResponse);

                        if (errorResponse.code === "authentication_required") {
                            OAuthStorageAdapter.clearStorage();
                            this.router.navigate(["/login"]);
                        }
                    } else {
                        if (err.status === 503) {
                            this.alertService.addCustomErrorByI18nId("global.errors.timeout");
                        } else {
                            this.alertService.addUnknownError(err);
                        }
                    }
                    return throwError(err);
                }
            ),
            finalize(() => {
                this.httpLoadingService.decrement();
            })
        );
    }
}
