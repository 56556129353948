import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { AuthService, OAuthStorageAdapter, SwitchOrganizationDialogComponent } from "@impacgroup/angular-oauth-base";
import { BaseComponent } from "@impacgroup/angular-baselib";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-sidebar-user",
    templateUrl: "./user.component.html",
    styleUrls: ["./user.component.scss"],
    animations: [
        trigger("toggleUserMenu", [
            state("void", style({
                height: "0",
                opacity: "0"
            })),
            state("true", style({
                height: "*",
                opacity: "1"
            })),
            transition(":enter", animate("200ms ease-in")),
            transition(":leave", animate("200ms ease-out"))
        ])
    ]
})
export class UserComponent extends BaseComponent implements OnInit {
    userMenu: boolean = false;
    email: string = "";
    organization: string = "";

    constructor(private oauthStorageAdapter: OAuthStorageAdapter, private authService: AuthService, private router: Router,
        private translateService: TranslateService, private modalService: BsModalService) {
        super();

        this.email = this.oauthStorageAdapter.getOAuthLoginResult().email;
        this.organization = this.oauthStorageAdapter.getOAuthLoginResult().organization;
    }

    ngOnInit() {
    }

    public switch() {
        const initialState = {
            title: this.translateService.instant("switchorganization.title"),
            message: this.translateService.instant("switchorganization.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(SwitchOrganizationDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            window.location.reload();
        };
    }

    public logout() {
        this.subscriptions.push(this.authService.logout().subscribe((result) => {
            OAuthStorageAdapter.clearStorage();
            this.router.navigate(["/login"]);
        }));
    }

    public editProfile() {
        this.router.navigate(["/profile"]);
    }
}
